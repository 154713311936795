@import '../../styles/index.scss';

.loader-container {
	.backdrop {
		position: fixed;
		width: 100vw;
		height: 100vh;
		margin: 0;
		padding: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 65536;
	}
	.show {
		display: block;
	}

	.hide {
		display: none;
	}

	.loader-spinner {
		position: absolute;
		left: calc(100vw - 50%);
		top: calc(100vh - 50%);
	}
}
