@import '../styles/index.scss';

.app-page-container {
	width: 100vw;
	padding: 0;
	padding-top: 1em;
	margin-top: 4em;
}

.content-header {
	font-weight: 300;
	font-size: 1.25em;
	padding-left: 1.25em;
	padding-top: 0.25em;
}

.request-history-container {
}

.content-container {
	display: flex;
	justify-content: center;
}
